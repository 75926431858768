@import "variables";
@import "reset";
@import "base";

// TODO: only load these two when needed
@import "~prismjs/themes/prism.css";
@import "~react-image-lightbox/style.css";

:root {
  // Shared Components
  --dominant-color: #{$dominantColor};
  --alt-color: #{$color1};
  --spinner-alt-color: #{$color3};
  --bg-color: #{$color3};
  --border-color: #{$color2};
}
