/*  Color Palette */
$color1: #E8E8E8;
$color2: #555753;
$color3: #282828;
$color4: #DD972E;
$color5: #729FCF;
$color6: #DD3F2E;
$color7: #4A9521;

/*  Color Settings */
$dominantColor: $color4;

/*  Breakpoints */
$tabletBreakpoint: 720px;
$desktopBreakpoint: 1280px;
$largeScreenBreakpoint: 1920px;

/*  Colors */
$colorLink: $color2;
$colorLinkHover: $color3;
$colorLinkDarkBg: $color1;
$colorLinkDarkBgHover: var(--dominant-color, #{$dominantColor});
$colorLinkActive: var(--dominant-color, #{$dominantColor});
$colorLinkFooter: var(--dominant-color, #{$dominantColor});
$colorLinkArchives: var(--dominant-color, #{$dominantColor});
$colorIcons: var(--dominant-color, #{$dominantColor});
$colorNavLink: $color3;
$colorTextLightBg: $color2;
$colorTextDarkBg: $color1;
$colorTextLinkSelected: var(--dominant-color, #{$dominantColor});
$colorTextMeta: $color3;
$colorTextQuote: $color2;
$colorTextCode: $color3;
$colorTextBox: white;
$colorTitle: white;
$colorTitleBg: $color3;
$colorTHeadBg: $color3;
$colorTitleAlt: $color3;
$colorTitleAltBg: white;
$colorBorderText: $color3;
$colorArchivesText: $color2;
$colorBgBox: $color3;
$colorBgTableOdd: mix($color1, white, 25%);
$colorBgCode: mix($color1, white, 25%);
$colorTFootBg: $color2;
$colorListMarker: mix($color2, white, 25%);
$colorFigureOutline: var(--dominant-color, #{$dominantColor});

/* Tags */
$colorTagBg: $color3;
$colorTagLink: white;
$colorTagLinkHoverBg: var(--dominant-color, #{$dominantColor});
$colorTagLinkHover: $color3;

/* Forms */
$colorFormBorder: $color2;
$colorFormEntryBorder: $color2;
$colorFormInvalid: $color6;
$colorFormSuccess: $color7;
$colorFormButtonBg: $color3;
$colorFormButton: white;
$colorFormButtonHoverBg: var(--dominant-color, #{$dominantColor});
$colorFormButtonHover: $color3;
$colorFormButtonDisabledBg: $color2;
$colorFormButtonDisabled: mix($colorFormButton, $color2, 75%);

/* Toast */
$colorToastBg: $color3;
$colorToastBorder: $color2;
$colorToastLink: var(--dominant-color, #{$dominantColor});

/*  Fonts */
$fontRobotoCondensed: 'Roboto Condensed', sans-serif;

/*  Header */
$colorBackground: $color3;
$colorHeaderAlt: white;
$colorHeaderBackgroundAlt: $color2;
$colorHeaderNavigationBg: $color3;
$colorFooterBackground: $color3;

/*  Borders */
$colorSquareBorder: $color2;
$colorSquareBorderHover: $color4;
$colorArchivesBorder: mix($color2, white, 15%);
$colorBorderTable: $color2;
$colorBorderMeta: $color3;
$colorBorderCode: $color3;
$colorBorderTitle: $color2;
$colorBorderTitleAlt: $color3;

/*  Durations */
$loadingDuration: 2s;
$transitionDuration: 300ms;
