@import "../00_base/variables";
@import "../00_base/mixins";

$margin: 8px;

.a-search-form {
  background: $colorHeaderNavigationBg;
  border-bottom: 1px solid $color2;
  display: flex;
  height: calc(100% - #{$margin} * 2);
  margin: $margin;
  padding: 0;
  width: calc(100% - #{$margin} * 2);

  &__input {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: $color1;
    flex: 1 1 100%;
    line-height: 1.3;
  }

  &__submit {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: $color1;
    cursor: pointer;
    flex: 0 0 auto;
    line-height: 0.8;
    margin: 0;
    padding: 0 0.4em;
    transition: color $transitionDuration;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      color: $colorLinkActive;
    }
  }
}
