@import '../00_base/variables';

.t-horizontal-thirds {
  background: white;
  margin: 0 auto;
  max-width: $largeScreenBreakpoint;
  min-height: 100vh;
  position: relative;
  width: 100%;

  &__footer {
    padding-bottom: 2em;
  }

  @supports (display: flex) {
    display: flex;
    flex-direction: column;

    &__header,
    &__footer {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__content {
      flex-grow: 1;
      overflow: hidden;
    }
  }

  @supports (display: grid) {
    @media (min-width: $desktopBreakpoint) {
      display: grid;
      grid-template-columns: 30% minmax(0, 1fr) 20%;

      &__header {
        grid-column: 1;
      }

      &__content {
        background: white;
        grid-column: 2;
        overflow: hidden;
        padding-bottom: 2em;
        z-index: 1;
      }

      &__footer {
        grid-column: 3;
      }
    }
  }
}
