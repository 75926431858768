@import "~normalize.css/normalize.css";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:focus:not(:focus-visible) {
  outline: none;
}
