@import 'variables';

html,
body {
  background: $colorBackground;

  @media (min-width: $desktopBreakpoint) {
    background: linear-gradient(to right, $colorHeaderAlt 50%, $colorBackground 50%);
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  @media (min-width: $desktopBreakpoint) {
    overflow-x: hidden;
    width: 100vw;
  }
}

.main-content {
  outline: 2px solid $colorBackground;
}

a {
  color: $colorLink;
  text-decoration: underline;
  transition-duration: ($transitionDuration/2);
  transition-property: opacity, color;

  &:hover,
  &:focus {
    color: $colorLinkHover;
  }

  &:active {
    color: $colorLinkActive;
  }
}

/*  Legacy styles */
strike {
  text-decoration: line-through;
}

.hidden {
  display: none;
}

em {
  font-style: italic;
}

strong,
.strong {
  font-weight: bold;
}

.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.aligncenter,
.box {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

big {
  font-size: 1.2em;
}

small {
  font-size: 0.8em;
}

img.alignleft {
  margin: 0.43em 1.86em 0.62em 0.43em;
}

img.alignright {
  margin: 0.43em 0.43em 0.62em 0.86em;
}

.hidden,
.hide,
.print-only {
  display: none;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

.has-text-align-left {
  text-align: right;
}
