@import "../../../../css/variables";

.m-vertical-list {
  list-style: none;
  margin: 1.3em 0;
  padding: 0;

  &__entry {
    margin: 0 0.25em 0.5em;
  }

  &--center {
    margin-left: 0.8em;
    text-align: center;
  }

  &--right {
    margin-left: 0.8em;
    text-align: right;
  }

  &--no-margin {
    margin: 0;
  }

  &--grid {
    @supports (display: grid) {
      display: grid;
      grid-template-columns: auto auto auto auto;

      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: auto auto;
      }
    }
  }

  &--grid-mobile {
    @supports (display: grid) {
      @media (max-width: $desktopBreakpoint) {
        display: grid;
        grid-template-columns: auto auto auto auto;
      }

      @media (max-width: $tabletBreakpoint) {
        display: grid;
        grid-template-columns: auto auto;
      }
    }
  }
}
