@import "../00_base/variables";

.a-site-logo {
  color: $dominantColor;

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}
