@import "../00_base/variables";

.m-site-navigation {
  background: $colorHeaderNavigationBg;
  color: $colorTextDarkBg;
  font-family: $fontRobotoCondensed;

  &__entry {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $colorTextDarkBg;
    cursor: pointer;
    margin: 0;
    padding: 0.6em 0.3em 0.3em;
  }

  &__entry,
  a {
    &,
    &:visited {
      background: transparent;
      color: $colorTextDarkBg;
      transition: ($transitionDuration/2) background-color;
    }

    &:hover,
    &:focus {
      background: mix($dominantColor, $colorHeaderNavigationBg, 20%);
      color: lighten($colorTextDarkBg, 10%);
    }

    &:active {
      background: $dominantColor;
      color: lighten($colorTextDarkBg, 10%);
    }

    &:disabled {
      &:visited,
      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: $colorFormButtonDisabledBg;
        cursor: initial;
      }
    }
  }
}
