@import '../00_base/variables';

.t-horizontal-halves {
  background: white;
  margin: 0 auto;
  max-width: $largeScreenBreakpoint;
  min-height: 100vh;
  position: relative;
  width: 100%;

  &__content {
    padding-bottom: 2em;
  }

  @supports (display: grid) {
    @media (min-width: $desktopBreakpoint) {
      display: grid;
      grid-template-columns: 30% minmax(0, 1fr);

      &__header {
        grid-column: 1;
      }

      &__content {
        grid-column: 2;
      }
    }
  }
}
