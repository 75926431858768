@import "../00_base/variables";

.o-site-footer {
  background: $colorFooterBackground;
  color: $colorTextDarkBg;
  padding: 1px 1em 1px 0;

  a {
    color: $colorLinkFooter;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $colorLinkFooter;
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  &__entry {
    margin-top: 1.3rem;

    &--external {
      font-family: $fontRobotoCondensed;
      font-size: 1.5em;
      margin-top: 0.8em;
    }

    &--offset {
      margin-top: 6.8rem;
    }

    @supports (position: sticky) {
      &--sticky {
        position: sticky;
        top: 0.5em;
      }
    }
  }
}
