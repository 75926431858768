@import "../00_base/variables";

.m-site-content {
  margin: 0.8rem 0;
  //overflow: hidden;

  &__entry {
    margin: 0.8rem 0.5rem 3rem 0;

    &:first-child {
      margin-top: 0;
    }
  }

  @media (min-width: $desktopBreakpoint) {
    margin: 1.4rem 0;

    &__entry {
      margin-right: 3rem;
    }
  }
}

.m-site-content__entry--margin.m-site-content__entry--margin {
  margin-left: 1rem;
  margin-right: 1rem;

  @media (min-width: $desktopBreakpoint) {
    margin-right: 3rem;
  }
}

.m-site-content__entry--no-margin.m-site-content__entry--no-margin {
  margin-right: 0;
}

.m-site-content__entry--margin-desktop.m-site-content__entry--margin-desktop {
  margin-right: 0;

  @media (min-width: $desktopBreakpoint) {
    margin-right: 3rem;
  }
}

.m-site-content__entry--text-margin.m-site-content__entry--text-margin {
  p,
  ul,
  ol {
    margin-left: 1rem;
    margin-right: 2rem;

    @media (min-width: $desktopBreakpoint) {
      margin-left: 2rem;
      margin-right: 0;
    }
  }
}
