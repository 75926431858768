@import "../00_base/variables";

.o-site-header {
  background: $colorHeaderBackgroundAlt;
  color: $colorTextDarkBg;
  padding-top: 3em;
  position: relative;
  width: 100%;
  z-index: 2;

  & > * {
    position: relative;
    z-index: 1;
  }

  &--image,
  &::after {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: initial;
  }

  &--image {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }

  &::after {
    background: $colorHeaderBackgroundAlt;
    content: ' ';
    opacity: 0.3;
    pointer-events: none;
  }

  &__navigation {
    box-shadow: 0 -5px 5px fade(black, 30%);
    display: grid;
    grid-template-columns: auto 3em;
    position: relative;
    width: 100%;

    @media (min-width: $desktopBreakpoint) {
      bottom: 0;
      position: absolute;
    }

    @supports (display: flex) {
      @media (min-width: $desktopBreakpoint) {
        position: relative;
      }
    }
  }

  &__menu {
    grid-column: 1;
    grid-row: 1;
  }

  &__search-button {
    grid-column: 2;
    grid-row: 1;
  }

  &__search-box {
    grid-column: 1;
    grid-row: 1;
  }

  &__logo {
    background: linear-gradient(to bottom, transparent, black);
    display: block;
    margin: 0;
    padding-top: 2em;
    width: 100%;
    z-index: 2;

    @media (min-width: $desktopBreakpoint) {
      bottom: 1.4em;
      position: absolute;
    }

    @supports (display: flex) {
      @media (min-width: $desktopBreakpoint) {
        bottom: initial;
        position: relative;
      }
    }
  }

  &__site-name {
    margin: 0;
    width: 45%;

    * {
      display: block;
      width: 100%;
    }

    a {
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }

    @media (max-width: $tabletBreakpoint) and (orientation: portrait) {
      width: 75%;
    }

    @supports (display: grid) {
      @media (min-width: $desktopBreakpoint) {
        width: 75%;
      }
    }
  }

  @media (max-width: $desktopBreakpoint) and (orientation: landscape) {
    padding-top: 1em;
  }

  @supports (position: sticky) {
    @media (max-width: $desktopBreakpoint) {
      padding-top: 12vh;
      position: sticky;
      top: calc(-12vh + 1em);
    }

    @media (max-width: $tabletBreakpoint) and (orientation: landscape) {
      padding-top: 1em;
      position: sticky;
      top: 0;
    }

    @media (max-width: $tabletBreakpoint) and (orientation: portrait) {
      padding-top: 30vh;
      top: calc(-30vh + 1em);
    }
  }

  @supports (display: grid) {
    @media (min-width: $desktopBreakpoint) {
      border-right: 2px solid $colorHeaderNavigationBg;
      height: 100%;
      left: 0;
      padding-top: 0;
      position: fixed;
      top: 0;
      width: 30%;
    }
  }

  @supports (display: grid) and (position: sticky) {
    @media (min-width: $desktopBreakpoint) {
      height: 100vh;
      position: sticky;
      width: 100%;
    }
  }

  @supports (display: flex) {
    @media (min-width: $desktopBreakpoint) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
